import React, { useRef, useState } from 'react';
import './css/App.css';
import siphonLogo from './img/siphon_logo_alpha.png';

import Showcase from './js/showcase/Showcase.js';
import { ShowcaseDefinitions } from './js/showcase/Data.js';

import Login, { logout, getExistingSession } from './js/login/Login.js';
import Uploader from './js/uploader/Uploader.js';
import ImageList from './js/imagelist/ImageList.js';

export default function App() {
    const isAutoAuthenticating = useRef( true );

    const [shouldShowLoadingBar, setShouldShowLoadingBar] = useState( true );
    const [shouldShowLogin, setShouldShowLogin] = useState( false );
    const [shouldShowUploader, setShouldShowUploader] = useState( false );
    const [shouldShowImageList, setShouldShowImageList] = useState( false );
    const [hasUpload, setHasUpload] = useState( false );
    const [isAuthenticated, setIsAuthenticated] = useState( false );
    const [userId, setUserId] = useState( -1 );
    const [username, setUsername] = useState( 'anonymous' );
    const [uploadDisplayName, setUploadDisplayName] = useState( null );
    const [uploadImageUrl, setUploadImageUrl] = useState( null );

    const PHP_ROOT = 'https://thesiphon.net/php/';

    // event handlers
    const loginCallbackFn = function( incomingUserId, incomingUsername ) {
        if ( incomingUserId > 0 && incomingUsername ) {
            setUserId( incomingUserId );
            setUsername( incomingUsername );
            setIsAuthenticated( true );
        }
        
        setShouldShowLoadingBar( false );
        setShouldShowLogin( false );
    }

    const logoutClickFn = function() {
        logout( PHP_ROOT + 'auth/logout.php', function() {
            setUserId( -1 );
            setUsername( null );
            setIsAuthenticated( false );
            setHasUpload( false );
            setUploadDisplayName( null );
            setUploadImageUrl( null );
        } );
    }

    const uploaderCallbackFn = function( displayName, imageUrl ) {
        if ( displayName && imageUrl ) {
            setHasUpload( true );
            setUploadDisplayName( displayName );
            setUploadImageUrl( imageUrl );
        }

        setShouldShowUploader( false );
    }
    
    const imageListCallbackFn = function() {
        setShouldShowImageList( false );
    }

    const loginClickFn = function() {
        setShouldShowLogin( true );
    }

    const uploadClickFn = function() {
        setShouldShowUploader( true );
    }

    const imageListClickFn = function() {
        setShouldShowImageList( true );
    }

    // one-time automatic authentication
    if ( userId < 0 && isAutoAuthenticating.current === true ) {
        isAutoAuthenticating.current = false;
        getExistingSession( PHP_ROOT + 'auth/session.php', loginCallbackFn );
    }

    // conditional renders
    let loginInfoRender = shouldShowLoadingBar === true
        ? <md-linear-progress indeterminate></md-linear-progress>
        : isAuthenticated 
            ? <div className="siphon-app-info">
                <span className="siphon-app-logged-in">logged in as: <strong>{username}</strong></span>
                <br />
                <md-icon-button className="siphon-app-upload" onClick={uploadClickFn}>
                    <md-icon class="material-symbols-outlined">Upload</md-icon>
                </md-icon-button>
                <md-icon-button className="siphon-app-imagelist" onClick={imageListClickFn}>
                    <md-icon class="material-symbols-outlined">Photo_Library</md-icon>
                </md-icon-button>
                <md-icon-button className="siphon-app-logout" onClick={logoutClickFn}>
                    <md-icon class="material-symbols-outlined">Logout</md-icon>
                </md-icon-button>
              </div>
            : <md-icon-button className="siphon-app-login" onClick={loginClickFn}>
                <md-icon class="material-symbols-outlined">Login</md-icon>
              </md-icon-button>;

    let loginRender = shouldShowLogin === true
        ? <Login actionUrl={(PHP_ROOT + 'auth/login.php')} callbackFn={loginCallbackFn} />
        : <></>;

    let uploaderRender = shouldShowUploader === true
        ? <Uploader actionUrl={(PHP_ROOT + 'upload/upload.php')} callbackFn={uploaderCallbackFn} />
        : <></>;

    let uploadInfoRender = hasUpload === true
        ? <span className="siphon-app-last-upload">last upload: <a href={uploadImageUrl} target="_blank" rel="noreferrer">{uploadDisplayName}</a></span>
        : <></>;

    let imageListRender = shouldShowImageList === true
        ? <ImageList listUrl={(PHP_ROOT + 'upload/list.php')} deleteUrl={(PHP_ROOT + 'upload/delete.php')} callbackFn={imageListCallbackFn} />
        : <></>;

    // output
    return (
        <div className="siphon-app">
            <header className="siphon-app-header">
                <div className="siphon-info">
                    <img src={siphonLogo} className="siphon-logo" alt="logo" />
                    <div>
                        <span className="siphon-app-title">thesiphon.net</span>
                        <br />
                        <code className="siphon-app-subtitle">
                            now with even more over-engineering
                            <br />
                            email: paul@thesiphon.net
                        </code>
                        <hr />
                        {loginInfoRender}
                        <br />
                        {uploadInfoRender}
                    </div>
                </div>
                <div className="siphon-app-showcases">
                    {ShowcaseDefinitions.map( sd => (
                        <Showcase 
                            key={sd.id} titleText={sd.titleText} siteUrl={sd.siteUrl} 
                            image={sd.image} descriptionText={sd.descriptionText} />
                    ))}
                </div>
            </header>
            {loginRender}
            {uploaderRender}
            {imageListRender}
        </div>
    );
}