import React, { useRef, useState } from 'react';
import '../../css/imagelist/ImageList.css';

export default function ImageList( props ) {
    const isFetching = useRef( false );
    const showList = useRef( false );
    const [imageData, setImageData] = useState( null );
    
    async function fetchData( url ) {
        let response = await fetch( url );
        let data = await response.json();

        setImageData( data );
        isFetching.current = false;
    };

    if ( imageData == null && isFetching.current === false ) {
        showList.current = false;
        isFetching.current = true;

        fetchData( props.listUrl );
    } else if ( imageData != null && imageData.length && imageData.length > 0 ) {
       showList.current = true;
    } // eo if

    async function handleDelete( imageId, e ) {
        e.preventDefault();

        let url = props.deleteUrl + '?imageId=' +imageId;
        let response = await fetch( url );
        
        // force re-fetch of list
        setImageData( null );
    }

    function handleClose( e ) {
        e.preventDefault();

        if ( typeof props.callbackFn === 'function' )
            props.callbackFn( null, null );
    }

    function testClose() {
        console( '[NFO] testClose fired...' );
    }

    let content = showList.current === true
        ? <md-list style={{ maxWidth: '500px', maxHeight: '800px' }}>
            <md-divider></md-divider>
            {imageData.map( image => (
                <>
                    <md-list-item>
                        <a href={image.imageUrl} target="_blank" rel="noreferrer">
                            <img slot="start" style={{ width: '64px' }} alt="" src={image.thumbnailUrl} />
                            <div style={{ display: 'inline-block' }}>
                                <div slot="headline"><strong>{image.displayName}</strong></div>
                                <div slot="supporting-text"><i>{image.dateUploaded}</i></div>
                            </div>
                        </a>
                        <a href="#" onClick={ ( e ) => handleDelete( image.id, e )}>
                            <md-icon slot="end" class="material-symbols-outlined" style={{ marginLeft: "10px", color: "red" }}>Delete</md-icon>
                        </a>
                    </md-list-item>
                    <md-divider></md-divider>
                </>
            ))}
          </md-list>
        : <md-linear-progress indeterminate></md-linear-progress>;

    return (
        <md-dialog onClose={testClose} open>
            <div slot="headline">
                Uploaded Images
            </div>
            <div slot="content">
                {content}
            </div>
            <div slot="actions">
                <md-text-button value="close" onClick={handleClose}>Close</md-text-button>
            </div>
        </md-dialog>
    );
}