import oldLocksImage from '../../img/old_locks.png';
import locksImage from '../../img/locks.png';
import newLocksImage from '../../img/new_locks.png';
import tsunamiImage from '../../img/tsunami.png';
import jArenaImage from '../../img/jArena.png';
import scraperImage from '../../img/scraper.png';

export const ShowcaseDefinitions = [{
    id: 1,
    titleText: 'locks 2011+', 
    siteUrl: 'https://thesiphon.net/sites/locks/index.php', 
    image: newLocksImage,
    descriptionText: 'Fancy latest version of the lock \'em challenge, now with $$rowdy bux$$ and 400% more degeneracy.'
  }, {
    id: 2,
    titleText: 'locks 2009-10', 
    siteUrl: 'https://thesiphon.net/sites/locks2/index.php', 
    image: locksImage,
    descriptionText: 'Gaming site that simulates online sports betting. Completely re-written with clean PHP and AJAX!'
  }, {
    id: 3,
    titleText: 'locks 2007-09', 
    siteUrl: 'https://thesiphon.net/sites/oldlocks/index.php', 
    image: oldLocksImage,
    descriptionText: 'Old version of locks (sketchy code design and no CSS, oops). Replaced locks.txt!'
  }, {
    id: 4,
    titleText: 'Tsunami Archive', 
    siteUrl: 'https://thesiphon.net/sites/tsunami/index.html', 
    image: tsunamiImage,
    descriptionText: 'Old intros, rosters, and other tidbits from the worst tribe of all time.'
  }, {
    id: 5,
    titleText: 'jArena', 
    siteUrl: 'https://thesiphon.net/sites/jArena/index.htm', 
    image: jArenaImage,
    descriptionText: '[NPAPI required] Battle for kitchen supremacy in Java! Inspired by true events or something.'
  }, {
    id: 6,
    titleText: 'Line Scraper', 
    siteUrl: '__NONE', 
    image: scraperImage,
    descriptionText: '[defunct] PHP/regex page that takes the latest NFL lines straight from Pinnacle Sportsbook.'
}];