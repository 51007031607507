import React, { Component } from 'react';
import '../../css/showcase/Showcase.css';

class Showcase extends Component {
    constructor( props ) {
        super( props );

        this.content = {
            key: props.id,
            titleText: props.titleText,
            siteUrl: props.siteUrl,
            image: props.image,
            descriptionText: props.descriptionText
        };

        this.isDeadLink = this.content.siteUrl === '__NONE';
    }

    clickHandler( isDeadLink, url ) {
        if ( !isDeadLink && url != null && url.length > 0 )
            window.location = url;
    }

    render() {
        let containerClassName = this.isDeadLink
            ? 'siphon-showcase-dead'
            : 'siphon-showcase';

        return (
            <div key={this.content.key} className={containerClassName} onClick={this.clickHandler.bind( this, this.isDeadLink, this.content.siteUrl )}>
                <md-elevation></md-elevation>
                <img src={this.content.image} className="siphon-showcase-image" alt="logo" />
                <div>
                    <span className="siphon-showcase-title">{this.content.titleText}</span>
                    <br />
                    <code className="siphon-showcase-description">{this.content.descriptionText}</code>
                </div>
            </div>
        );
    };
  }
  
  export default Showcase;