import React, { useState } from 'react';
import '../../css/uploader/Uploader.css';

export default function Uploader( props ) {
    const [isUploading, setIsUploading] = useState( false );

    async function handleSubmit( e ) {
        e.preventDefault();
        setIsUploading( true );

        const formData = new FormData();

        const fileInput = document.getElementById( 'siphon-uploader-incomingFile' );
        formData.append( 'incomingFile', fileInput.files[0])

        const response = await fetch( props.actionUrl, {
            method: 'POST',
            body: formData
        } );

        const result = await response.json();

        if ( typeof props.callbackFn === 'function' )
            props.callbackFn( result.displayName, result.imageUrl );

        setIsUploading( false );
    };
    
    function handleCancel( e ) {
        e.preventDefault();

        if ( typeof props.callbackFn === 'function' )
            props.callbackFn( null, null );
    }

    let fileInputDisabled = isUploading === true;
    let actionsRender = isUploading === true
        ? <md-linear-progress indeterminate style={{width: 250 + 'px'}}></md-linear-progress>
        : <>
            <md-text-button form="siphon-uploader-form" value="cancel" onClick={handleCancel}>Cancel</md-text-button>
            <md-filled-button form="siphon-uploader-form" value="submit">Upload</md-filled-button>
          </>;

    return (
        <md-dialog open>
            <div slot="headline">
                Upload Image
            </div>
            <form slot="content" id="siphon-uploader-form" onSubmit={handleSubmit} action={props.actionUrl} method="post" encType="multipart/form-data">
                <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                <input type="file" id="siphon-uploader-incomingFile" name="incomingFile" disabled={fileInputDisabled} />
            </form>
            <div slot="actions">
                {actionsRender}
            </div>
        </md-dialog>
    );
}