import '../../css/login/Login.css';

export async function getExistingSession( actionUrl, callbackFn ) {
    const response = await fetch( actionUrl, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    } );

    const result = await response.json();

    if ( typeof callbackFn === 'function' )
        callbackFn( result.userId, result.username );
};

export async function logout( actionUrl, callbackFn ) {
    const response = await fetch( actionUrl, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    } );

    const result = await response.json();

    if ( typeof callbackFn === 'function' )
        callbackFn( result.userId, result.username );
};

export default function Login( props ) {
    async function handleSubmit( e ) {
        e.preventDefault();

        const request = {
            username: e.currentTarget.elements.username.value,
            password: e.currentTarget.elements.password.value
        };

        const response = await fetch( props.actionUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( request )
        } );

        const result = await response.json();

        if ( typeof props.callbackFn === 'function' )
            props.callbackFn( result.userId, result.username );
    };
    
    function handleCancel( e ) {
        e.preventDefault();

        if ( typeof props.callbackFn === 'function' )
            props.callbackFn( null, null );
    }

    return (
        <md-dialog open>
            <div slot="headline">
                Log In
            </div>
            <form slot="content" id="siphon-login-form" onSubmit={handleSubmit} action={props.actionUrl} method="post">
                <md-outlined-text-field label="username" name="username" required />
                <md-outlined-text-field label="password" name="password" type="password" required />
            </form>
            <div slot="actions">
                <md-text-button form="siphon-login-form" value="cancel" onClick={handleCancel}>Cancel</md-text-button>
                <md-filled-button form="siphon-login-form" value="submit">Login</md-filled-button>
            </div>
        </md-dialog> 
    );
}